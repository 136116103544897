import React, { useEffect, useState } from "react";
import { logFormSubmission } from "../../../utils/google-tag-manager";
import fullText from "../../../../content/data/investor-page.json";

const CallToAction = props => {
  const { document, isPreview } = props;
  const language = "en";
  const translatedText = fullText[language];

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  //when global pages are set up via CMS, this will come as data via props
  const submittedMessage = "Thanks for registering your details. We'll get in touch with you soon.";

  const submitForm = async evt => {
    evt.preventDefault();
    if (isPreview) {
      setSubmitted(true);
      return;
    } // If we are in the CMS don't trigger a save.

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {},
    };

    const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/25354625/bece31a9-0a06-4fea-8998-d82787cad9a4', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setSubmitted(true);
      logFormSubmission("investor_factsheet_download");
    }
  };

  return (
    <div className="container investor-input-container">
      <div className="col-md-6 col-sm-8 float-none-sm float-lg-none investor-input-col-xsm">
        <div className="subscribe-content subscribe-mobile">
          {submitted ? (
              <h4 className="px-4 investor-success-message investor-success-message-sm investor-success-message-dark bg-info">
                {submittedMessage}
              </h4>
          ) : (
              <form className="newsletter-form pb-5" onSubmit={submitForm}>
                <div className="investor-control-container">
                  <input
                      type="text"
                      className="input-newsletter investor-header-input"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                  />
                  <button
                      type="submit"
                      className="base-btn font-medium investor-signup-button"
                  >
                    {translatedText["investInSeriesBText"]}
                    <span></span>
                  </button>
                </div>
              </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
