import React from "react";
import ReactWOW from "react-wow";

const ProductGreenSteps = props => {
  const {
    mainTitle,
    title1,
    title2,
    title3,
    subtitle1,
    subtitle2,
    subtitle3,
    description1,
    description2,
    description3,
    summary,
  } = props;
  const hideComponent = Object.values(props).every(x => x === null || x === "");

  return hideComponent ? null : (
    <section className="bg-black green-steps-area">
      <div className="container">
        <div className="">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <div className="col-12 col-lg-11 text-white ">
              <h4 className="green-steps-title">{mainTitle}</h4>
            </div>
          </ReactWOW>
          <div className="row green-steps-triangle-group">
            <div className="col-12 col-sm-11 col-md-10 col-lg-4 mx-auto green-steps-triangle">
              <h2 className="text-primary">{title1}</h2>
              <h4 className="text-white">{subtitle1}</h4>
              <p className="text-white small">{description1}</p>
            </div>
            <div className="col-12 col-sm-11 col-md-10 col-lg-4 mx-auto green-steps-triangle">
              <h2 className="text-primary">{title2}</h2>
              <h4 className="text-white">{subtitle2}</h4>
              <p className="text-white small">{description2}</p>
            </div>
            <div className="col-12 col-sm-11 col-md-10 col-lg-4 mx-auto green-steps-triangle">
              <h2 className="text-primary">{title3}</h2>
              <h4 className="text-white">{subtitle3}</h4>
              <p className="text-white small">{description3}</p>
            </div>
          </div>
          <div className="col-11 col-md-10 col-lg-9 mx-auto green-stripes-investors">
            <h4 className="text-white d-table investor-quote">{summary}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductGreenSteps;
