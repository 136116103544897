import React, {useState} from "react"
import { Link } from 'gatsby'
import {logFormSubmission} from "../../../utils/google-tag-manager";
import fullText from "../../../../content/data/investor-page.json";

const FactSheet = props => {
  const { document, isPreview } = props

  const [email, setEmail] = useState("");
  const language = "en";
  const translatedText = fullText[language];

  const submitForm = async (evt) => {
    evt.preventDefault();
    if (isPreview) return; // If we are in the CMS don't trigger a save.

    const data = {
      "fields": [
        {
          "name": "email",
          "value": email
        }
      ],
      "context": {
        // "pageUri": "http://localhost:8000",
        // "PageName": "Product Page Australia",
        // "skipValidation": true
      },
    };
    const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/25354625/bece31a9-0a06-4fea-8998-d82787cad9a4', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      logFormSubmission('investor_factsheet_download');

      const fakeLink = window.document.createElement("a");
      fakeLink.setAttribute("href", document);
      fakeLink.setAttribute("target", "_blank");
      fakeLink.click();
    }
  };

  return (
    <section className="info-investor-area bg-darkgray">
      <div className="container">
        <div className="row ">
          <div className="flex-center-vertically investor-upsell-info">
              <span className="text-white green-stripes-investors-info">
                <span>
                    <span className="text-primary text-uppercase">{translatedText['upsellTitle']} </span>
                    {translatedText['upsellSubtext']}
                </span>
              </span>
            <div>
          </div>
        </div>
        </div>
      </div>
    </section>
  )
}

export default FactSheet
