import React, { useEffect, useState } from "react";
import ReactWOW from "react-wow";
import { logFormSubmission } from "../../../utils/google-tag-manager";
import fullText from "../../../../content/data/investor-page.json";

const FactSheet = props => {
  const { isPreview } = props;
  const language = "en";
  const translatedText = fullText[language];

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  //when global pages are set up via CMS, this will come as data via props
  const submittedMessage = "Thanks for registering your details. We'll get in touch with you soon.";

  const submitForm = async evt => {
    evt.preventDefault();
    if (isPreview) {
      setSubmitted(true);
      return;
    } // If we are in the CMS don't trigger a save.

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        // "pageUri": "http://localhost:8000",
        // "PageName": "Product Page Australia",
        // "skipValidation": true
      },
    };

    const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/25354625/56a035fb-7ffe-4a5a-a79a-6fc1e61e2df5', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setSubmitted(true);
      logFormSubmission("investor_factsheet_download");
    }
  };

  return (
    <section className="factsheet-area bg-primary">
      <div className="container">
        {submitted ? (
            <h4 className="px-4 success-message-form-primary bg-primary">
              {submittedMessage}
            </h4>
        ) : (
            <>
              <div className="row">
                <div className="col-12 ">
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <h4 className="text-center footer-title">
                      Join our investor community
                    </h4>
                  </ReactWOW>
                </div>
              </div>

              <div className="col-8 m-auto">
                <div className="subscribe-content">
                  <form className="newsletter-form pb-5" onSubmit={submitForm}>
                    <input
                        type="text"
                        className="input-newsletter"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                    <button
                        type="submit"
                        className="default-btn bg-black text-white font-medium investor-community-button"
                    >
                      {translatedText["footerContactButtonText"]}{" "}
                      <span></span>
                    </button>
                  </form>
                  <h4 className="text-center">
                    Sign up to receive our monthly round-up of industry news and
                    insight
                  </h4>
                </div>
              </div></>
        )}

      </div>
    </section>
  );
};

export default FactSheet;
