import React from "react"
import ReactWOW from "react-wow"

const ProductMain = props => {
  const {  } = props
  return (
    <React.Fragment>
      <div className="product-above-area">
        <div className="container-fluid">
          <div className="row align-items-end">
            <div className="col-12 col-lg-6 text-center mx-auto parent-block ps-lg-0">
              <div className="product-cover"></div>
            </div>
            <div className="col-12 col-lg-6 text-center text-lg-start text-under-main-image pe-lg-5 ps-lg-5">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                TP24 is a Zürich based FinTech with offices in Switzerland, Australia, the UK and Netherlands. We’re making it our mission to solve some of the world’s biggest lending challenges. For banks. For insurers. And for business owners.
                </p>
                <p>
                Leading the way to a better future for business credit by creating products that customers actually want. All backed by experienced people, data insights and clever technology.
                </p>
              </ReactWOW>
            </div>
          </div>
          <div className="row align-items-end pb-6">
            <div className="col-10 text-center mx-auto pt-3">
              <h1 className="display-5 font-medium pt-7 pb-6">
              The business <span className="text-primary">of tomorrow</span>
              </h1>
            </div>
            <div className="col-12 col-lg-6 text-start m-auto py-3 pb-5">
              <p>
                We're excited to announce that we officially launched part one of our Series B. The focus of the round will be to build the sales & marketing infrastructure for our tech in order to globally scale.
              </p>
              <p>
                For more detailed information about our platform and solutions, or to discover investment opportunities, sign up to our monthly investor newsletter.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductMain
