import React from "react"
import LogoSymbol from "../../../assets/images/tp-logo.svg"
import FadeLeftAnimation from "../../common/FadeLeftAnimation";

const InvestorBanner = props => {
  const { } = props
  return (
    <div className="it-services-banner">
      <div className="container position-relative">
        <div className="row align-items-center">
          <div className="col-12 col-lg-8">
            <div className="text-white innerpage-title">
              <FadeLeftAnimation>
                <h4 className="pb-4">For investors</h4>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h1>
                  Invest in the incomparable
                  <span className="text-primary">
                    <br />
                    Laser focused on progress
                  </span>
                </h1>
              </FadeLeftAnimation>
            </div>
          </div>
        </div>
        <img src={LogoSymbol} alt="logo" className="product-child-img d-none d-lg-block" />
      </div>
    </div>
  )
}

export default InvestorBanner
