import React from "react";
import Layout from "../components/global/Layout";
import InvestorBanner from "../components/global/investors/InvestorBanner";
import InvestorMain from "../components/global/investors/InvestorMain";
import InvestorInfo from "../components/global/investors/InvestorInfo";

import InvestorGreenGradient from "../components/global/investors/InvestorGreenGradient";
import InvestorProducts from "../components/global/investors/InvestorProducts";
import InvestorNewsletter from "../components/global/investors/InvestorNewsletter";
import InvestorMajor from "../components/global/investors/InvestorMajor";
import InvestorCommunity from "../components/global/investors/InvestorCommunity";
import { parseSeoFromQuery } from "../components/global/SEO";
import ProductGreenSteps from "../components/global/product/ProductGreenSteps";
import CallToAction from '../components/global/investors/InvestorCallToAction';

export default function Investors(props) {
  // Not ordered as in CMS! =/
  const data = props?.data?.markdownRemark?.frontmatter;
  const disableSections = true;
  
  const productVerticals = {
      mainTitle: "Our verticals",
      title1: "1. CreditLine",
      title2: "2. Technology", 
      title3: "3. Data & Product", 
      subtitle1: "Data-driven lending", 
      subtitle2: "Credit Engine", 
      subtitle3: "Informed Insights", 
      description1: "A unique solution for medium and large SME’s backbone working capital needs. Launched in Switzerland, Australia, UK and the Netherlands via a direct distribution model.",
      description2: "A unique solution for medium and large SME’s backbone working capital needs. Launched in Switzerland, Australia, UK and the Netherlands via a direct distribution model.",
      description3: "A unique solution for medium and large SME’s backbone working capital needs. Launched in Switzerland, Australia, UK and the Netherlands via a direct distribution model.",
      summary: "These verticals support our vision to make B2B SMEs anywhere in the world more bankable and insurable by unleashing the combined power of financial, accounting, transactional & market data for lenders, banks & insurers everywhere."
  }
  
  return (
    <Layout {...parseSeoFromQuery(props.data)}>
       <CallToAction {...data?.investorCommunity} />
      <InvestorBanner {...data?.investorBanner} />
      <InvestorMain {...data?.investorMain} />
      <InvestorInfo {...data?.investorInfo} />
        {!disableSections && (
            <>
                <ProductGreenSteps {...productVerticals}/>
                <InvestorGreenGradient {...data?.investorGreenGradient} />
                <InvestorProducts {...data?.investorProducts} />
                <InvestorNewsletter {...data?.investorNewsletter} />
                <InvestorMajor {...data?.investorMajor} />
            </>
        )}
      <InvestorCommunity {...data?.investorCommunity} />
    </Layout>
  );
}
